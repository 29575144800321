<template>
  <div>
    <h3 class="page-title">ئىگە باشقۇرۇش</h3>
    <el-divider></el-divider>
    <div class="rtl-right search-box">
      <el-row :gutter="10">
        <el-col :span="5">
          <el-select v-model="filterForm.ownerable_type" class="rtl-right" @change="typeChange()" clearable placeholder="تۈرى">
            <el-option
              v-for="item in ownerableTypeList"
              :key="item.name"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="5" v-if="filterForm.ownerable_type == 'agents'">
          <el-select v-model="filterForm.ownerable_id" class="rtl-right" @change="toSearch()" clearable placeholder="ئوبيېكت">
            <el-option
              v-for="item in agentList"
              :key="item.id"
              :label="item.name_ug"
              :value="item.id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="5" v-if="filterForm.ownerable_type == 'vips'">
          <el-select v-model="filterForm.ownerable_id" class="rtl-right" @change="toSearch()" clearable placeholder="ئوبيېكت">
            <el-option
              v-for="item in vipList"
              :key="item.id"
              :label="item.name_ug"
              :value="item.id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-input v-model="filterForm.user_name" @keyup.native.enter="toSearch()" clearable placeholder="ئەزا ئىسمى كىرگۈزۈڭ"></el-input>
        </el-col>
        <el-col :span="5">
          <el-input v-model="filterForm.uid" @keyup.native.enter="toSearch()" clearable placeholder="ئەزا uid كىرگۈزۈڭ"></el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="success" plain icon="el-icon-search" @click="toSearch()">ئىزدەش </el-button>
          <el-button type="danger" plain icon="el-icon-circle-close" @click="toClear()">بىكار  قىلىش </el-button>
        </el-col>
      </el-row>
    </div>
    <!-- table-box -->
    <div class="table-box" v-if="pageTags.list">
      <el-table
        :data="tableData" 
        border 
        style="width: 100%" 
        v-loading="loading">
        <!-- <el-table-column prop="image" label="باش سۈرەت" width="100">
          <template slot-scope="scope">
            <img :src="scope.row.avatar" class="table-img">
          </template>
        </el-table-column> -->
        <el-table-column prop="image" label="باش سۈرەت" width="100">
          <template slot-scope="scope">
            <img :src="scope.row.user.avatar" class="table-img">
          </template>
        </el-table-column>
        <el-table-column prop="user.name" label="ئەزا ئىسمى"></el-table-column>
        <!-- <el-table-column prop="user.phone" label="تېلېفون"></el-table-column> -->
        <el-table-column prop="user.uid" label="uid"></el-table-column>
        <el-table-column prop="ownerable_type" label="ئىگە تۈرى">
          <template slot-scope="scope">
            <span v-if="scope.row.ownerable_type == 'vips'">ئالاھىدە ئەزا</span>
            <span v-if="scope.row.ownerable_type == 'courses'">دەرس </span>
            <span v-if="scope.row.ownerable_type == 'agents'">ۋاكالەتچى</span>
            <!-- <span v-if="scope.row.ownerable_type == 'teachers'">ئوقۇتقۇچى</span> -->
          </template>
        </el-table-column>
        <el-table-column prop="ownerable.name_ug" label="ئوبيېكت"></el-table-column>
        <el-table-column prop="expired_at" label="ئاخىرلىشىش چىسلاسى"></el-table-column>
        <el-table-column prop="surplus_at" label="ئاخىرلىشىش ۋاقتى"></el-table-column>
        <el-table-column label="مەشغۇلات" prop="dosome" width="300">
          <template slot-scope="scope">
            <el-button size="mini" type="success" @click="handleOwner(scope.row)">تەھرىرلەش</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">ئۆچۈرۈش</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        background
        :current-page="current_page"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        layout="sizes,prev, pager, next,total,jumper"
        :total="total">
      </el-pagination>
    </div>

    <!-- edit dialog -->
    <el-dialog
      title="تەھرىرلەش"
      :visible.sync="dialogVisible"
      width="30%">
      <div class="rtl-right form-content">
        <el-form label-width="120px">
          <el-form-item label="ئاخىرلىشىش ۋاقتى" prop="expired_at">
            <el-date-picker v-model="expired_at" type="date" value-format="yyyy-MM-dd" placeholder="ئاخىرلىشىش ۋاقتى"></el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" plain @click="submitForm">مۇقىملاش</el-button>
        <el-button type="danger" plain @click="dialogVisible = false">بىكار قىلىش</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<style lang="scss" scoped>
  .el-date-editor{
    width: 100%;
  }
  .search-box{
    .el-select,.el-input,.el-date-editor{
      width: 100%;
    }
    [class*="el-col-"]{
      float: right;
    }
  }
  .table-img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #EBEEF5;
  }
</style>

<script>
  var self;
  export default {
    activated: function() {
      self = this;
      self.getList();
      self.getAgenetList();
      self.getVipList();
    },
    data() {
      return {
        tableData: [],
        loading: false,
        per_page:10,
        current_page:1,
        last_page:0,
        total:0,
        pageTags:{},
        curItem:{},
        dialogVisible:false,
        expired_at:"",
        filterForm:{
          ownerable_type:null,
          ownerable_id:null,
          user_name:null, 
          uid:null
        },
        ownerableTypeList:[
          {
            name:"ۋاكالەتچى",
            value:"agents"
          },
          // {
          //   name:"ئوقۇتقۇچى",
          //   value:"teachers"
          // },
          {
            name:"ئالاھىدە ئەزا",
            value:"vips"
          },
          {
            name:"دەرس",
            value:"courses"
          }
        ],
        agentList:[],
        vipList:[]        
      };
    },
    methods: {
      getList() {
        self.loading = true;
        self.$fetch("admin/owner",{
          page:self.current_page,
          per_page:self.per_page,
          ownerable_type:self.filterForm.ownerable_type,
          ownerable_id:self.filterForm.ownerable_id,
          user_name:self.filterForm.user_name,
          uid:self.filterForm.uid
        }).then(response => {
          if (response.status == 200) {
            self.tableData = response.data.data;
            self.total = response.data.meta.total;
            self.current_page = response.data.meta.current_page;
            self.last_page = response.data.meta.last_page;
          } else{
            console.log(response.message);
          }
          self.loading = false;
          if(JSON.parse(sessionStorage.getItem("curTags"))){
            self.pageTags = JSON.parse(sessionStorage.getItem("curTags"));
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
          self.loading = false;
        });
      },
      handleSizeChange(val) {
        self.per_page = val;
        self.getList();
      },
      handleCurrentChange(val) {
        self.current_page = val;
        self.getList();
      },
      typeChange(){
        self.filterForm.ownerable_id = null;
        self.toSearch();
      },
      toSearch(){
        self.current_page = 1;
        if(self.filterForm.ownerable_type == 'courses'){
          self.filterForm.ownerable_id = null;
        }
        self.getList();
      },
      toClear(){
        self.filterForm = {
          ownerable_type:null,
          ownerable_id:null,
          user_name:null, 
          uid:null 
        }
        self.toSearch();
      },
      getAgenetList(){
        self.$fetch("admin/agent").then(response => {
          if (response.status == 200) {
            self.agentList = response.data.data;
          } else{
            console.log(response.message);
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
        });
      },
      getVipList(){
        self.$fetch("admin/vip").then(response => {
          if (response.status == 200) {
            self.vipList = response.data.data;
          } else{
            console.log(response.message);
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
        });
      },
      handleOwner(row){
        self.curItem = row;
        self.expired_at = self.curItem.expired_at;
        self.dialogVisible = true;
      },
      handleDelete(index, row) {
        self.$confirm("ئۆچۈرۈشنى مۇقۇملامسىز ؟", "ئەسكەرتىش", {
          confirmButtonText: "مۇقىملاش",
          cancelButtonText: "بىكار قىلىش",
          type: "warning"
        }).then(() => {
          self.$remove('admin/owner/'+row.id).then((response) => {
            console.log(response);
            if(response.status == 204){
              self.getList();
              self.$message({
                message: "ئۆچۈرۈش تاماملاندى",
                type: "success",
                duration: 1200
              });
            }else{
              console.log(response);
            }
          }).catch(err => {
            console.log('××××××××××××××××');
            console.log(err);
          });

        }).catch(() => {
          self.$message({
            type: "info",
            message: "ئۆچۈرۈش بىكار قىلىندى"
          });
        });
      },
      submitForm(){
        self.$put('admin/owner/'+self.curItem.id,{
          expired_at:self.expired_at
        }).then((response) => {
          if(response.status == 201){
            self.dialogVisible = false;
            self.getList();
            self.$message({
              message: response.data.message,
              type: "success",
              duration: 1200
            });
          }else{
            self.$message({
              message: response.data.message,
              type: "warning",
              duration: 1500
            });
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
        });
      }
    }
  };
</script>
